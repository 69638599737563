.rollout-default {
  margin: 0 auto;
}

.rollout-default-frame {
  display: block;
  border-style: none;
}

.rollout-spacing {
  margin-top: 50px !important;
  margin-bottom: -65px;
}
