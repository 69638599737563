.circular-image-small {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.card {
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.06),
      0px 2px 2px 0px rgba(0, 0, 0, 0.042), 0px 1px 5px 0px rgba(0, 0, 0, 0.036);
  }
  @media print {
    border: 0;
  }
}
.card.ignore-border {
  &:hover {
    box-shadow: none;
  }
  border: 0;
}
.card-title {
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 500;
}
.card-subtitle {
  font-size: 0.875rem;
  .theme-dark & {
    color: rgba(#fff, 0.54);
  }
}

.hide-on-mobile {
  display: inherit;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.hide-on-pc {
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.show-on-pc {
  @media screen and (max-width: 1200px) {
    display: none !important;
  }
}

.VictoryContainer {
  svg {
    height: 100% !important;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.circle-44 {
  height: 44px !important;
  width: 44px !important;
}

.circle-32 {
  height: 32px !important;
  min-height: 32px !important;
  width: 32px !important;

  .MuiFab-root {
    min-height: 32px !important;
  }
  .MuiIcon-root {
    font-size: 13px !important;
  }
}

.show-on-mobile {
  display: none !important;
  @include media(767px) {
    display: inherit !important;
  }
}

// .invisible-on-pc {
//   visibility: hidden;
//   @include media(767px) {
//     visibility: visible;
//   }
// }

.highlight-js {
  pre {
    white-space: pre-line;
  }
}
