/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    top: 100px;
    width: auto;
    margin: 0 auto;
    padding-bottom: 24px;
    pointer-events: none;
  }
  .ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }
  .ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 18.5px;
    line-height: 22px;
    word-wrap: break-word;
  }
  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: auto;
  }
  .ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-modal-close:focus,
  .ant-modal-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
  }
  .ant-modal-body {
    padding: 24px;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
  }
  .ant-modal-footer button + button {
    margin-bottom: 0;
    margin-left: 8px;
  }
  .ant-modal.zoom-enter,
  .ant-modal.zoom-appear {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    opacity: 0;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
  }
  .ant-modal-mask-hidden {
    display: none;
  }
  .ant-modal-open {
    overflow: hidden;
  }
  .ant-modal-centered {
    text-align: center;
  }
  .ant-modal-centered::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }
  .ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    .ant-modal {
      max-width: calc(100vw - 16px);
      margin: 8px auto;
    }
    .ant-modal-centered .ant-modal {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
    }
  }
  .ant-modal-confirm .ant-modal-header {
    display: none;
  }
  .ant-modal-confirm .ant-modal-close {
    display: none;
  }
  .ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px;
  }
  .ant-modal-confirm-body-wrapper {
    zoom: 1;
  }
  .ant-modal-confirm-body-wrapper::before,
  .ant-modal-confirm-body-wrapper::after {
    display: table;
    content: '';
  }
  .ant-modal-confirm-body-wrapper::after {
    clear: both;
  }
  .ant-modal-confirm-body .ant-modal-confirm-title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
  }
  .ant-modal-confirm-body > .anticon {
    float: left;
    margin-right: 16px;
    font-size: 22px;
  }
  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 38px;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px;
  }
  .ant-modal-confirm .ant-modal-confirm-btns button + button {
    margin-bottom: 0;
    margin-left: 8px;
  }
  .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: #f5222d;
  }
  .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
  .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: #faad14;
  }
  .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #f9004d;
  }
  .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #52c41a;
  }
  