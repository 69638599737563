.form-wizard {
  .container {
    @extend .px-0;
    ol {
      display: none;
    }
    .content {
      min-height: 25rem;
      padding: 2rem;
      border-radius: 5px;
      border: 1px solid $border-color;
      overflow: scroll;
    }
    > div {
      &:not(.content) {
        margin-top: 20px;
        text-align: right;
        @media (max-width: 767px) {
          text-align: center;
        }
        > button {
          @extend .btn-large;
          + button {
            margin-left: 10px;
            @media (max-width: 767px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
