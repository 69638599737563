.payment-label {
  font-size: 1rem !important;
}

button.payment {
  width: 100%;
  font-family: $font-1 !important;
  padding: 5px 22px;
  font-size: 1rem;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #f9004d;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 400;
  line-height: 1.75;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  text-decoration: none;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  display: inline-flex;
  outline: 0;
  align-items: center;
  user-select: none;
  border: 0;
}

div.payment-field:focus-within {
  label.payment {
    color: #f9004d !important;
  }
  input.payment {
    border-color: #f9004d !important;
  }
}

div.payment-field:hover {
  label.payment {
    color: #f9004d !important;
  }
  input.payment {
    border-color: #f9004d !important;
  }
}

label.payment {
  font-family: $font-3 !important;
  font-size: 1.1rem !important;
  color: #abb8c5 !important;
  margin: 0 !important;
  padding: 0 !important;
}

input.payment {
  font-family: $font-3 !important;
  font-size: 1.05rem !important;
  letter-spacing: 0.05em;
  margin: 0 !important;
  padding: 0 !important;
  outline: 0 !important;
  border-width: 0 0 2px !important;
  border-color: #dee3ea !important;
}

input.payment#postal {
  font-family: $font-3 !important;
}

input.payment::placeholder {
  color: #aab7c4;
}

div.payment-info {
  font-family: $font-3 !important;
  font-size: 1.1rem;
  margin: 1rem 0;
  width: 100%;
}

div.align-left {
  float: left;
}

div.align-right {
  float: right;
}

div.clear-float {
  clear: both;
}

.s-elem-base {
  font-size: 22px !important;
  line-height: 28px !important;
}

.s-elem-complete {
  font-size: 2rem !important;
}

.s-elem-empty {
  font-size: 2rem !important;
}

.s-elem-focus {
  font-size: 2rem !important;
}

.s-elem-invalid {
  font-size: 2rem !important;
}

.s-elem-autofill {
  font-size: 2rem !important;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(64, 64, 109, 0.1);
  max-height: 60px;
  font-size: 0.95rem !important;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.spinner:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0px auto;
  box-sizing: border-box;
  border: 12px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loading 1.2s infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
