@mixin clearfix() {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin config-bg-colors($prefix, $bg_color...) {
  @each $i in $bg_color {
    .#{$prefix}#{nth($i, 1)} {
      background: nth($i, 2);
    }
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

// Gradient color
@mixin gradient-color($value, $deg) {
  @if $value==a {
    background: -webkit-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: -moz-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: -ms-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: -o-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: linear-gradient(
      $deg#{deg},
      #f71595 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    text-fill-color: transparent;
  } @else if $value==b {
    background: -webkit-linear-gradient(
      $deg#{deg},
      rgb(251, 110, 57) 12%,
      rgb(252, 85, 93) 50%,
      rgb(253, 59, 128) 91%
    );
    background: -moz-linear-gradient(
      $deg#{deg},
      rgb(251, 110, 57) 12%,
      rgb(252, 85, 93) 50%,
      rgb(253, 59, 128) 91%
    );
    background: -ms-linear-gradient(
      $deg#{deg},
      rgb(251, 110, 57) 12%,
      rgb(252, 85, 93) 50%,
      rgb(253, 59, 128) 91%
    );
    background: -o-linear-gradient(
      $deg#{deg},
      rgb(251, 110, 57) 12%,
      rgb(252, 85, 93) 50%,
      rgb(253, 59, 128) 91%
    );
    background: linear-gradient(
      $deg#{deg},
      rgb(251, 110, 57) 12%,
      rgb(252, 85, 93) 50%,
      rgb(253, 59, 128) 91%
    );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    text-fill-color: transparent;
  } @else if $value==c {
    background: -webkit-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: -moz-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: -ms-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: -o-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    text-fill-color: transparent;
  }
}

// Border radius
@mixin border-radius($br-value) {
  -webkit-border-radius: $br-value;
  -moz-border-radius: $br-value;
  -ms-border-radius: $br-value;
  -o-border-radius: $br-value;
  border-radius: $br-value;
}

// Transition
@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

// Transition
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// Gradient Background
@mixin gradient-bg($value, $deg) {
  @if $value==a {
    background: -webkit-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: -moz-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: -ms-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: -o-linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
    background: linear-gradient(
      $deg#{deg},
      #fe0190 0%,
      #ed560e 62%,
      #ff9600 100%
    );
  } @else if $value==b {
    background: -webkit-linear-gradient(
      $deg#{deg},
      rgba(223, 32, 32, 1) 0%,
      rgba(0, 0, 0, 0.6) 36%,
      rgba(0, 0, 0, 0.3) 100%
    );
    background: -moz-linear-gradient(
      $deg#{deg},
      rgba(223, 32, 32, 1) 0%,
      rgba(0, 0, 0, 0.6) 36%,
      rgba(0, 0, 0, 0.3) 100%
    );
    background: -ms-linear-gradient(
      $deg#{deg},
      rgba(223, 32, 32, 1) 0%,
      rgba(0, 0, 0, 0.6) 36%,
      rgba(0, 0, 0, 0.3) 100%
    );
    background: -o-linear-gradient(
      $deg#{deg},
      rgba(223, 32, 32, 1) 0%,
      rgba(0, 0, 0, 0.6) 36%,
      rgba(0, 0, 0, 0.3) 100%
    );
    background: linear-gradient(
      $deg#{deg},
      rgba(223, 32, 32, 1) 0%,
      rgba(0, 0, 0, 0.6) 36%,
      rgba(0, 0, 0, 0.3) 100%
    );
  } @else if $value==c {
    background: -webkit-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: -moz-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: -ms-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: -o-linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
    background: linear-gradient(
      $deg#{deg},
      rgb(40, 172, 225) 12%,
      rgb(81, 204, 231) 50%,
      rgb(122, 235, 236) 91%
    );
  }
}

// Flexbox
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

// Inline flex
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// Flexbox Direction
@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

// Flexbox Wrap
@mixin flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

// Flexbox Flow (shorthand)
@mixin flex-flow(
  $values: (
    row nowrap,
  )
) {
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

// Flexbox Order
@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

// Flexbox Grow
@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

// Flexbox Shrink
@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

// Flexbox Basis
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

// Flexbox "Flex" (shorthand)
@mixin flex($fg: 1, $fs: null, $fb: null) {
  $fg-boxflex: $fg;
  @if type-of($fg) == "list" {
    $fg-boxflex: nth($fg, 1);
  }
  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

// Flexbox Justify Content
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}
// Shorter version:
@mixin flex-just($args...) {
  @include justify-content($args...);
}

//----------------------------------------------------------------------

// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

//----------------------------------

// Flexbox Align Self
//
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

//----------------------------------------------------------------------

// Flexbox Align Content
//
// The 'align-content' property aligns a flex container's lines within the
// flex container when there is extra space in the cross-axis, similar to
// how 'justify-content' aligns individual items within the main-axis. Note,
// this property has no effect when the flexbox has only a single line.
//
// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-content-property

@mixin align-content($value: stretch) {
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

// Spacing

@mixin generate-margin-padding-in-rem($from, $to) {
  @for $i from $from through $to {
    .m-#{$i} {
      margin: #{$i * 0.25}rem;
    }
    .mt-#{$i} {
      margin-top: #{$i * 0.25}rem !important;
    }
    .mr-#{$i} {
      margin-right: #{$i * 0.25}rem !important;
    }
    .mb-#{$i} {
      margin-bottom: #{$i * 0.25}rem !important;
    }
    .ml-#{$i} {
      margin-left: #{$i * 0.25}rem !important;
    }
    .mx-#{$i} {
      margin-left: #{$i * 0.25}rem !important;
      margin-right: #{$i * 0.25}rem !important;
    }
    .my-#{$i} {
      margin-top: #{$i * 0.25}rem !important;
      margin-bottom: #{$i * 0.25}rem !important;
    }

    .p-#{$i} {
      padding: #{$i * 0.25}rem !important;
    }
    .pt-#{$i} {
      padding-top: #{$i * 0.25}rem !important;
    }
    .pr-#{$i} {
      padding-right: #{$i * 0.25}rem !important;
    }
    .pb-#{$i} {
      padding-bottom: #{$i * 0.25}rem !important;
    }
    .pl-#{$i} {
      padding-left: #{$i * 0.25}rem !important;
    }
    .px-#{$i} {
      padding-left: #{$i * 0.25}rem !important;
      padding-right: #{$i * 0.25}rem !important;
    }
    .py-#{$i} {
      padding-top: #{$i * 0.25}rem !important;
      padding-bottom: #{$i * 0.25}rem !important;
    }
  }
}

@mixin generate-margin-padding-in-px($from, $to) {
  @for $i from $from through $to {
    .m-#{$i}px {
      margin: #{$i}px;
    }
    .mt-#{$i}px {
      margin-top: #{$i}px !important;
    }
    .mr-#{$i}px {
      margin-right: #{$i}px !important;
    }
    .mb-#{$i}px {
      margin-bottom: #{$i}px !important;
    }
    .ml-#{$i}px {
      margin-left: #{$i}px !important;
    }
    .mx-#{$i}px {
      margin-left: #{$i}px !important;
      margin-right: #{$i}px !important;
    }
    .my-#{$i}px {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }

    .p-#{$i}px {
      padding: #{$i}px !important;
    }
    .pt-#{$i}px {
      padding-top: #{$i}px !important;
    }
    .pr-#{$i}px {
      padding-right: #{$i}px !important;
    }
    .pb-#{$i}px {
      padding-bottom: #{$i}px !important;
    }
    .pl-#{$i}px {
      padding-left: #{$i}px !important;
    }
    .px-#{$i}px {
      padding-left: #{$i}px !important;
      padding-right: #{$i}px !important;
    }
    .py-#{$i}px {
      padding-top: #{$i}px !important;
      padding-bottom: #{$i}px !important;
    }
  }
}

@mixin generate-height-width($from, $to) {
  @for $i from $from through $to {
    @if $i % 4 == 0 {
      .w-#{$i} {
        width: #{$i}px !important;
      }
      .min-w-#{$i} {
        min-width: #{$i}px !important;
      }
      .max-w-#{$i} {
        max-width: #{$i}px !important;
      }
      .h-#{$i} {
        height: #{$i}px !important;
      }
      .min-h-#{$i} {
        min-height: #{$i}px !important;
      }
      .max-h-#{$i} {
        max-height: #{$i}px !important;
      }
    }
  }
}

// media
@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// Animation
@mixin keyframeMaker($name) {
  @keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
}
