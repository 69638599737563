/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-typography.ant-typography-secondary {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-typography.ant-typography-warning {
    color: #faad14;
  }
  .ant-typography.ant-typography-danger {
    color: #f5222d;
  }
  .ant-typography.ant-typography-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  div.ant-typography,
  .ant-typography p {
    margin-bottom: 1em;
  }
  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 38px;
    line-height: 1.23;
  }
  h2.ant-typography,
  .ant-typography h2 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.35;
  }
  h3.ant-typography,
  .ant-typography h3 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
  }
  h4.ant-typography,
  .ant-typography h4 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
  }
  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: 1.2em;
  }
  .ant-typography div + h1,
  .ant-typography ul + h1,
  .ant-typography li + h1,
  .ant-typography p + h1,
  .ant-typography h1 + h1,
  .ant-typography h2 + h1,
  .ant-typography h3 + h1,
  .ant-typography h4 + h1,
  .ant-typography div + h2,
  .ant-typography ul + h2,
  .ant-typography li + h2,
  .ant-typography p + h2,
  .ant-typography h1 + h2,
  .ant-typography h2 + h2,
  .ant-typography h3 + h2,
  .ant-typography h4 + h2,
  .ant-typography div + h3,
  .ant-typography ul + h3,
  .ant-typography li + h3,
  .ant-typography p + h3,
  .ant-typography h1 + h3,
  .ant-typography h2 + h3,
  .ant-typography h3 + h3,
  .ant-typography h4 + h3,
  .ant-typography div + h4,
  .ant-typography ul + h4,
  .ant-typography li + h4,
  .ant-typography p + h4,
  .ant-typography h1 + h4,
  .ant-typography h2 + h4,
  .ant-typography h3 + h4,
  .ant-typography h4 + h4 {
    margin-top: 1.2em;
  }
  span.ant-typography-ellipsis {
    display: inline-block;
  }
  .ant-typography a {
    color: #f9004d;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-typography a:focus,
  .ant-typography a:hover {
    color: #f9004d;
  }
  .ant-typography a:active {
    color: #f9004d;
  }
  .ant-typography a:active,
  .ant-typography a:hover {
    text-decoration: none;
  }
  .ant-typography a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none;
  }
  .ant-typography code {
    margin: 0 0.2em;
    padding: 0.2em 0.4em 0.1em;
    font-size: 85%;
    background: rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }
  .ant-typography mark {
    padding: 0;
    background-color: #ffe58f;
  }
  .ant-typography u,
  .ant-typography ins {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
  }
  .ant-typography s,
  .ant-typography del {
    text-decoration: line-through;
  }
  .ant-typography strong {
    font-weight: 600;
  }
  .ant-typography-expand,
  .ant-typography-edit,
  .ant-typography-copy {
    color: #f9004d;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    margin-left: 8px;
  }
  .ant-typography-expand:focus,
  .ant-typography-edit:focus,
  .ant-typography-copy:focus,
  .ant-typography-expand:hover,
  .ant-typography-edit:hover,
  .ant-typography-copy:hover {
    color: #f9004d;
  }
  .ant-typography-expand:active,
  .ant-typography-edit:active,
  .ant-typography-copy:active {
    color: #f9004d;
  }
  .ant-typography-copy-success,
  .ant-typography-copy-success:hover,
  .ant-typography-copy-success:focus {
    color: #52c41a;
  }
  .ant-typography-edit-content {
    font-size: 1rem !important;
    position: relative;
  }
  div.ant-typography-edit-content {
    font-size: 1rem !important;
    left: -12px;
    margin-top: -5px;
    margin-bottom: calc(1em - 4px - 2px);
  }
  .ant-typography-edit-content-confirm {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: rgba(0, 0, 0, 0.45);
    pointer-events: none;
  }
  .ant-typography-edit-content textarea {
    color: #000000 !important;
    margin: 8px 0;
    overflow-wrap: break-word;
    span {
        font-weight: 500;
    }
    font-size: 1.12rem;
    line-height: 1.5rem;
    min-height: 2.5rem !important;
    -moz-transition: none;
  }
  .ant-typography ul,
  .ant-typography ol {
    margin: 0 0 1em 0;
    padding: 0;
  }
  .ant-typography ul li,
  .ant-typography ol li {
    margin: 0 0 0 20px;
    padding: 0 0 0 4px;
  }
  .ant-typography ul li {
    list-style-type: circle;
  }
  .ant-typography ul li li {
    list-style-type: disc;
  }
  .ant-typography ol li {
    list-style-type: decimal;
  }
  .ant-typography-ellipsis-single-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-typography-ellipsis-multiple-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  