.landing-page {
  h1 {
    &.title {
      font-size: 125px;
      font-weight: 900;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 900;
      line-height: 130px;
      @media #{$laptop-device} {
        font-size: 100px;
        line-height: 112px;
      }
      @media #{$lg-layout} {
        font-size: 90px;
        line-height: 103px;
      }
      @media #{$md-layout} {
        font-size: 70px;
        line-height: 82px;
        margin-bottom: 0;
      }
      @media #{$sm-layout} {
        font-size: 50px;
        line-height: 70px;
        margin-bottom: 0;
      }
      @media #{$large-mobile} {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 20px;
      }
    }
  }
}

.rn-page-title {
  h1 {
    &.title {
      color: #ffffff;
      font-size: 100px;
      font-weight: 900;
      @media #{$lg-layout} {
        font-size: 72px;
      }
      @media #{$md-layout} {
        font-size: 60px;
        margin-bottom: 18px;
      }
      @media #{$sm-layout} {
        font-size: 60px;
        line-height: 64px;
        margin-bottom: 36px;
      }
      span {
        font-weight: 600;
      }
    }
  }
}

div.hidden-large-view {
  display: none !important;
  @media #{$lg-layout} {
    display: none !important;
  }
  @media #{$md-layout} {
    display: flex !important;
  }
  @media #{$sm-layout} {
    display: flex !important;
  }
}

div.hidden-unless-mobile {
  display: none !important;
  @media #{$lg-layout} {
    display: none !important;
  }
  @media #{$md-layout} {
    display: none !important;
  }
  @media #{$sm-layout} {
    display: flex !important;
  }
}

div.mt-30 {
  margin-top: 30px;
}

div.same-height {
  height: 100%;
}

div.same-height-less {
  height: 90%;
}

.col-center-block {
  float: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.analytics-container {
  background: #206BA3;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 6rem !important;

  @media #{$sm-layout} {
    padding: 4rem 0 6rem 0 !important;
  }
}

.analytics-subcontainer {
  width: 100% !important;
  margin-top: -4.5rem !important;
  padding: 0 6rem !important;

  @media #{$sm-layout} {
    margin-top: -3.75rem !important;
    padding: 0 1rem !important;
  }
}

.analytics-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0.11rem;
  color: white !important;
}

.analytics-full {
  width: 100% !important;
  margin: 0 !important;
}

.w-100-p {
  width: 100% !important;
}
