.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.product-table {
  white-space: pre;
  min-width: 400px;
  overflow: auto;
  small {
    height: 15px;
    width: 50px;
    border-radius: 500px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  }

  tbody {
    tr {
      transition: background 300ms ease;
      &:hover {
        background: #999;
      }
      td {
        border-bottom: none;
        text-transform: capitalize;
      }
      td:first-child {
        padding-left: 16px !important;
      }
    }
  }
}

.play-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  small {
    line-height: 1;
  }
}

.sales {
  .bills {
    .bills__icon {
      border-radius: 8px;
      height: 52px;
      width: 52px;
      overflow: hidden;
      background-color: rgba(24, 42, 136, 0.08);
      h4,
      h5 {
        color: rgba(0, 0, 0, 0.87);
      }
      img {
        height: 23px;
        width: 36.76px;
      }
    }
  }
}

.analytics {
  .face-group {
    .avatar {
      border: 2px solid white;
      &:not(:first-child) {
        margin-left: -14px;
      }
    }
    .number-avatar {
      background: red;
    }
  }

  .small-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;

    .small-icon {
      font-size: 8px;
    }
  }

  .project-card {
    .card__roject-name {
      margin-left: 24px;
      @include media(767px) {
        margin-left: 4px;
      }
    }
  }
}

// online education
.learning-management {
  position: relative;

  .welcome-card {
    position: relative;
    padding: 36px 50px !important;
    overflow: visible;

    img {
      margin-top: -82px;
      max-width: 230px;
    }

    @include media(767px) {
      img {
        display: none;
      }
    }
  }

  .product-table {
    white-space: pre;
    min-width: 400px;
    overflow: auto;
    small {
      height: 15px;
      width: 50px;
      border-radius: 500px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    }

    tbody {
      tr {
        transition: background 300ms ease;
        &:hover {
          background: #999;
        }
        td {
          border-bottom: none;
          text-transform: capitalize;
        }
        td:first-child {
          padding-left: 16px !important;
        }
      }
    }
  }
}

.account-status {
  width: auto !important;
  height: auto !important;
  font-size: 14px !important;
  padding: 5px 15px !important;
}

.bg-status-active {
  background: #13a100 !important;
}

.bg-status-pending {
  background: #ff8800 !important;
}

.bg-status-inactive {
  background: #7c7c7c !important;
}

.bg-status-disabled {
  background: #ee0000 !important;
}

.theme-admin {
  background: #7467ef !important;
  border: 2px solid #7467ef !important;
}

.rn-btn-green {
  background: #18cf00 !important;
  border: 2px solid #18cf00 !important;
}

.rn-btn-orange {
  background: #ff8800 !important;
  border: 2px solid #ff8800 !important;
}

.rn-btn-red {
  background: #f9004d !important;
  border: 2px solid #f9004d !important;
}

.rn-btn-dark {
  background: #343a40 !important;
  border: 2px solid #343a40 !important;
}

.rn-row-green {
  background: rgba(24, 207, 0, 0.35) !important;
}

.rn-row-orange {
  background: rgba(255, 136, 0, 0.35) !important;
}

.rn-btn-disabled {
  cursor: not-allowed !important;
  color: white !important;
}
