.fmt-text-display {
    color: #000000 !important;
    margin: 8px 0;
    overflow-wrap: break-word;
    span {
        font-weight: 500;
    }
    font-size: 1.12rem;
}

hr {
  border: 0 !important;
  clear: both !important;
  display: block !important;
  width: 100% !important;               
  background-color:#e8e8e8 !important;
  height: 1px !important;
  margin: 25px 0 !important;
}