.survey-default {
  margin: 0 auto;
}

.survey-default-frame {
  display: block;
  border-style: none;
}

.survey-footer-spacing {
  margin-bottom: -35px;
}
